import { AppProps } from "next/app";
import "../styles/fonts/font-awesome/css/font-awesome.css";
import "../styles/common.scss";
import "@fontsource/roboto";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import TagManager from "react-gtm-module";
import { useEffect } from "react";
import React from "react";
import { HydrationProvider, Client } from "react-hydration-provider";
export default function MyApp({ Component, pageProps }: AppProps, { data }: any) {
  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-WM8XKV" });
    TagManager.initialize({ gtmId: "GTM-NFWH4JJG" });
  }, []);

  return (
    <HydrationProvider>
      <Component {...pageProps} />
    </HydrationProvider>
  );
}
